import React from 'react'
import styled from 'styled-components'

import { ReactComponent as Logo } from '../../../shared/static/icons/gorrion_logo_basic.svg'

const Container = styled.div`
  position: fixed;
  top: 16px;
  left: 24px;
  z-index: 99999;

  @media all and (max-width: 1199px) {
    ${(props) =>
      props.shouldCenter &&
      `
      display: flex;
      position: static;
      top: 0;
      left: 0;
      margin-bottom: 40px;
      padding-top: 40px;
      align-items: center;
      justify-content: center;
      `}
  }
`
const Header = ({ shouldCenter }) => {
  return (
    <Container shouldCenter={shouldCenter}>
      <a href="https://gorrion.io" target="_blank" rel="noopener noreferrer">
        <Logo />
      </a>
    </Container>
  )
}

export default Header
