import styled from 'styled-components'
import colors from '../../shared/styles/colors'

export const Container = styled.div`
  min-height: 100vh;
  width: 100vw;
  background-color: ${colors.veryLightGrey};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  svg {
    width: 531px;
    @media (min-width: 380px) {
      width: 556px;
    }
  }
`

export const Title = styled.div`
  width: 90%;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.5;
  color: ${colors.veryDarkGrey};
  margin-top: 48px;
  margin-bottom: 16px;
  @media (min-width: 380px) {
    width: 420px;
  }
`

export const Text = styled.div`
  font-size: 16px;
  line-height: 1.5;
  color: ${colors.greyTeritary};
  width: 78%;
  @media (min-width: 380px) {
    width: 420px;
  }
`
