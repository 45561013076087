import React from 'react'
import DefaultHeader from './Header/Header'
import { MainContainer } from './AuthorizedLayout.components'

const AuthorizedLayout = ({ children }) => {
  return (
    <div style={{ minHeight: '100vh' }}>
      <div>
        <DefaultHeader />
        <MainContainer>{children}</MainContainer>
      </div>
    </div>
  )
}

export default AuthorizedLayout
