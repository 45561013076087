import React from 'react'
import AppEventTarget from '../../../../common/events/AppEventTarget'
import LogoutRequestEvent from '../../../../common/events/LogoutRequestEvent'
import { ProjectName, Container, StyledLogo } from './Header.components'
import { SecondaryMediumButton } from '../../../shared/components/Button/Button'

const Header = () => {
  const handleLogout = () => {
    AppEventTarget.dispatchEvent(new LogoutRequestEvent())
  }
  return (
    <Container>
      <ProjectName>
        <StyledLogo />
        Brief online
      </ProjectName>
      <SecondaryMediumButton onClick={handleLogout}>
        Logout
      </SecondaryMediumButton>
    </Container>
  )
}

export default Header
