import { appEventTypes } from './AppEventTarget'

class LogoutRequestEvent extends CustomEvent {
  constructor(settings) {
    super(appEventTypes.LOGOUT_REQUEST, {
      detail: {
        redirectPathname: settings?.redirectPathname,
      },
    })
  }
}

export default LogoutRequestEvent
