import styled, { css } from 'styled-components'
import colors from '../../styles/colors'

export const Container = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;

  opacity: 0;
  pointer-events: none;
  z-index: 10000;
  user-select: none;
  > * {
    user-select: text;
  }

  transition: opacity 330ms ease-in-out;
  ${(p) =>
    (p.status === 'entering' || p.status === 'entered') &&
    css`
      opacity: 1;
      pointer-events: all;
    `};
`

export const Background = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${colors.veryDarkGrey};
  z-index: 10000;
  opacity: 0;

  transition: opacity 330ms ease-in-out;
  ${(p) =>
    (p.status === 'entering' || p.status === 'entered') &&
    css`
      opacity: 0.6;
    `};
`
