import styled from 'styled-components'

export const MainContainer = styled.div`
  width: 80vw;
  margin-right: auto;
  margin-left: auto;
  a {
    color: ${(props) => props.theme.main};
  }
`
