import React from 'react'
import { useMediaQuery } from 'react-responsive'
import Header from './Header/Header'
import { Container, Text, Title } from './TokenLayout.components'
import { ReactComponent as Illustration } from '../../shared/static/mobile-illustration.svg'

const TokenLayout = ({ children, routeComponentProps }) => {
  const isWelcomePage =
    routeComponentProps.match.path === '/map/:id/:page?' &&
    (!routeComponentProps.match.params.page ||
      routeComponentProps.match.params.page === ':page')
  const isMobile = useMediaQuery({ query: '(max-width: 599px)' })
  return (
    <>
      {isMobile && (
        <Container>
          <Illustration />
          <Title>We don’t have enough space!</Title>
          <Text>
            Please open this page on a device with a bigger resolution (tablet or laptop) to see
            all options.
          </Text>
        </Container>
      )}
      {!isMobile && (
        <>
          <Header shouldCenter={isWelcomePage} />
          {children}
        </>
      )}
    </>
  )
}

export default TokenLayout
