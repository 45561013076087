import { useState } from "react";
import * as firebase from "firebase/app";

export const useLogout = () => {
  const [{ loading, error }, setState] = useState({
    loading: false,
    error: null
  })
  
  const logout = async () => {
    try {
      setState({ error: false, loading: true });
      const res = await firebase.auth().signOut();
      setState({ error: false, loading: false });
      return res;
    }
    catch(err) {
      setState({  error: err.message, loading: false });
    }
  }

  return [logout, loading, error];
}
