import colors from './colors'
import colorTheme from './colorThemes.enum'

export const themeHandler = (theme) => {
  switch (theme) {
    case colorTheme.GREEN:
      return { main: colors.green, hover: colors.greenHover }
    case colorTheme.VIOLET:
      return { main: colors.violet, hover: colors.violetHover }
    case colorTheme.ORANGE:
      return { main: colors.orange, hover: colors.orangeHover }
    case colorTheme.RED:
      return { main: colors.redError, hover: colors.redErrorHover }
    default:
      return { main: colors.violet, hover: colors.violetHover }
  }
}
