import { localStorageKeys } from './localStorageKeys'
import AppEventTarget from '../events/AppEventTarget'
import TokenChangeEvent from '../events/TokenChangeEvent'

export const setUserToken = userToken => {
  localStorage.setItem(localStorageKeys.USER_TOKEN, JSON.stringify(userToken))
  AppEventTarget.dispatchEvent(new TokenChangeEvent(userToken))
}

export const deleteUserToken = () => {
  setUserToken(null)
}

export const getUserToken = () => {
  try {
    const userToken = JSON.parse(
      localStorage.getItem(localStorageKeys.USER_TOKEN) ?? 'null'
    )

    if (userToken === null) {
      return null
    }

    ;['expiresAt', 'token'].forEach(key => {
      if (!(key in userToken)) {
        throw new Error(`Missing key: ${key}`)
      }
    })

    userToken.expiresAt = new Date(userToken.expiresAt)
    if (isNaN(userToken.expiresAt)) {
      throw new Error('Invalid expiration date')
    }

    return userToken
  } catch (err) {
    console.error(`Error parsing user token ${err.message}`)
    deleteUserToken()
    return null
  }
}
