import React, { lazy, Suspense, useState } from 'react'
import { withRouter, Switch, Route } from 'react-router-dom'
import 'antd/dist/antd.css'
import { ThemeProvider } from 'styled-components'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import routes from '../Routing/routes'
import ScreenLoading from '../Routing/ScreenLoading'
import GlobalStyle from '../shared/styles/global'
import AuthorizedRoute from '../Routing/AuthorizedRoute'
import PublicRoute from '../Routing/PublicRoute'
import TokenRoute from '../Routing/TokenRoute'
import useTokenChangeHandler from '../../common/eventHandlers/useTokenChangeHandler'
import useLogoutRequestHandler from '../../common/eventHandlers/useLogoutRequestHandler'
import AppContext from './AppContext'
import { themeHandler } from '../shared/styles/themeHandler'
import colorThemesEnum from '../shared/styles/colorThemes.enum'
import ModalProvider from '../shared/components/Modal/ModalProvider'

const routeComponents = routes.map((routeConfig, index) => {
  let RouteComponent
  const component = lazy(routeConfig.loader)
  switch (routeConfig.type) {
    case 'authed':
      RouteComponent = AuthorizedRoute
      break
    case 'public':
      RouteComponent = PublicRoute
      break
    case 'token':
      RouteComponent = TokenRoute
      break
    default:
      RouteComponent = PublicRoute
      break
  }

  return <RouteComponent key={index} component={component} {...routeConfig} />
})

const App = () => {
  const [token] = useTokenChangeHandler();
  useLogoutRequestHandler();
  const [state, setState] = useState({
    userToken: token,
    cantGoToNextSection: false, 
  });
  const context = {
    ...state,
    setContext: fragment => setState({ ...state, ...fragment })
  }
  return (
    <AppContext.Provider value={context}>
      <GlobalStyle />
      <DndProvider backend={HTML5Backend}>
        <ThemeProvider theme={themeHandler(colorThemesEnum.VIOLET)}>
          <ModalProvider>
            <Suspense fallback={<ScreenLoading />}>
              <Switch>
                {routeComponents}
                <Route to="/" component={() => {  window.location.href = "https://gorrion.io/404" }} />
              </Switch>
            </Suspense>
          </ModalProvider>
        </ThemeProvider>
      </DndProvider>
    </AppContext.Provider>
  )
}

export default withRouter(App)
