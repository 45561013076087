import React, { useEffect } from 'react'
import { Transition } from 'react-transition-group'
import { Background, Container } from './ModalContainer.components'

const ModalContainer = ({ children, isOpen, position, onClose }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      setTimeout(() => {
        document.body.style.overflow = 'unset'
      }, 300)
    }
  }, [isOpen])
  return (
    <Transition
      timeout={330}
      mountOnEnter={true}
      unmountOnExit={true}
      appear={true}
      in={isOpen}>
      {(status) => (
        <>
          <Background status={status} />
          <Container status={status} onClick={onClose} position={position}>
            {/* Pass extra status prop to children element */}
            {children && <children.type {...children.props} status={status} />}
          </Container>
        </>
      )}
    </Transition>
  )
}

export default ModalContainer
