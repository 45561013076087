import React, { useCallback, useMemo, useState } from 'react'

import ModalContext from './ModalContext'
import ModalContainer from './ModalContainer'

const ModalProvider = ({ children }) => {
  const [state, setState] = useState({
    isOpen: false,
  })

  const contextValue = useMemo(
    () => ({
      openModal(content) {
        setState({
          content,
          isOpen: true,
        })
      },
      closeModal() {
        setState((prevState) => ({
          ...prevState,
          isOpen: false,
        }))
      },
    }),
    []
  )

  const handleClose = useCallback(
    (event) => {
      event.preventDefault()
      if (event.target !== event.currentTarget) {
        return
      }
      contextValue.closeModal()
    },
    [contextValue]
  )
  return (
    <ModalContext.Provider value={contextValue}>
      <ModalContainer isOpen={state.isOpen} onClose={handleClose}>
        {state.content}
      </ModalContainer>
      {children}
    </ModalContext.Provider>
  )
}

export default ModalProvider
