import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import AppEventTarget, { appEventTypes } from '../events/AppEventTarget'
import { deleteUserToken } from '../helpers/userTokens'
import { rootPaths } from '../../modules/Routing/routesPaths'
import { useLogout } from '../../config/firebase/hooks/useLogout'

const useLogoutRequestHandler = () => {
  const [logout] = useLogout();
  const history = useHistory()

  useEffect(() => {
    const handler = async () => {
      await logout();
      deleteUserToken();
      history.replace(rootPaths.LOGIN);
      console.info('SUCCESSFULLY LOGGED OUT');
    }

    AppEventTarget.addEventListener(appEventTypes.LOGOUT_REQUEST, handler)
    return () => {
      AppEventTarget.removeEventListener(appEventTypes.LOGOUT_REQUEST, handler)
    }
  }, [history, logout])
}

export default useLogoutRequestHandler
