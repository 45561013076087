import React from 'react'
import { Route } from 'react-router-dom'
import TokenLayout from '../App/TokenLayout/TokenLayout'

const TokenRoute = ({ component: Component, ...routeProps }) => {
  return (
    <Route
      {...routeProps}
      render={(routeComponentProps) => (
        <TokenLayout routeComponentProps={routeComponentProps}>
          <Component {...routeComponentProps} />
        </TokenLayout>
      )}
    />
  )
}

export default TokenRoute
