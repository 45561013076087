import { createGlobalStyle } from 'styled-components'
import colors from './colors'
import SofiaProBlack from '../static/fonts/SofiaProBlack.otf'
import SofiaProBlackItalic from '../static/fonts/SofiaProBlackItalic.otf'
import SofiaProBold from '../static/fonts/SofiaProBold.otf'
import SofiaProBoldItalic from '../static/fonts/SofiaProBoldItalic.otf'
import SofiaProExtraLight from '../static/fonts/SofiaProExtraLight.otf'
import SofiaProExtraLightItalic from '../static/fonts/SofiaProExtraLightItalic.otf'
import SofiaProItalic from '../static/fonts/SofiaProItalic.otf'
import SofiaProLight from '../static/fonts/SofiaProLight.otf'
import SofiaProLightItalic from '../static/fonts/SofiaProLightItalic.otf'
import SofiaProMedium from '../static/fonts/SofiaProMedium.otf'
import SofiaProMediumItalic from '../static/fonts/SofiaProMediumItalic.otf'
import SofiaProRegular from '../static/fonts/SofiaProRegular.otf'
import SofiaProSemiBold from '../static/fonts/SofiaProSemiBold.otf'
import SofiaProSemiBoldItalic from '../static/fonts/SofiaProSemiBoldItalic.otf'
import SofiaProUltraLight from '../static/fonts/SofiaProUltraLight.otf'
import SofiaProUltraLightItalic from '../static/fonts/SofiaProUltraLightItalic.otf'

const GlobalStyle = createGlobalStyle`
@font-face {
  font-family: SofiaPro;
  font-style: normal;
  font-weight: 900;
  src:
    local('SofiaPro'),
    local('Sofia Pro'),
    url(${SofiaProBlack}) format('opentype');;
}
@font-face {
  font-family: SofiaPro;
  font-style: italic;
  font-weight: 900;
  src:
    local('SofiaPro'),
    local('Sofia Pro'),
    url(${SofiaProBlackItalic}) format('opentype');;
}
@font-face {
  font-family: SofiaPro;
  font-style: normal;
  font-weight: 800;
  src:
    local('SofiaPro'),
    local('Sofia Pro'),
    url(${SofiaProBold}) format('opentype');;
}
@font-face {
  font-family: SofiaPro;
  font-style: italic;
  font-weight: 800;
  src:
    local('SofiaPro'),
    local('Sofia Pro'),
    url(${SofiaProBoldItalic}) format('opentype');;
}
@font-face {
  font-family: SofiaPro;
  font-style: normal;
  font-weight: 700;
  src:
    local('SofiaPro'),
    local('Sofia Pro'),
    url(${SofiaProSemiBold}) format('opentype');;
}
@font-face {
  font-family: SofiaPro;
  font-style: italic;
  font-weight: 700;
  src:
    local('SofiaPro'),
    local('Sofia Pro'),
    url(${SofiaProSemiBoldItalic}) format('opentype');;
}
@font-face {
  font-family: SofiaPro;
  font-style: normal;
  font-weight: 600;
  src:
    local('SofiaPro'),
    local('Sofia Pro'),
    url(${SofiaProMedium}) format('opentype');;
}
@font-face {
  font-family: SofiaPro;
  font-style: italic;
  font-weight: 600;
  src:
    local('SofiaPro'),
    local('Sofia Pro'),
    url(${SofiaProMediumItalic}) format('opentype');;
}
@font-face {
  font-family: SofiaPro;
  font-style: normal;
  font-weight: 500;
  src:
    local('SofiaPro'),
    local('Sofia Pro'),
    url(${SofiaProRegular}) format('opentype');;
}
@font-face {
  font-family: SofiaPro;
  font-style: italic;
  font-weight: 500;
  src:
    local('SofiaPro'),
    local('Sofia Pro'),
    url(${SofiaProItalic}) format('opentype');;
}
@font-face {
  font-family: SofiaPro;
  font-style: normal;
  font-weight: 400;
  src:
    local('SofiaPro'),
    local('Sofia Pro'),
    url(${SofiaProLight}) format('opentype');;
}
@font-face {
  font-family: SofiaPro;
  font-style: italic;
  font-weight: 400;
  src:
    local('SofiaPro'),
    local('Sofia Pro'),
    url(${SofiaProLightItalic}) format('opentype');;
}
@font-face {
  font-family: SofiaPro;
  font-style: normal;
  font-weight: 200;
  src:
    local('SofiaPro'),
    local('Sofia Pro'),
    url(${SofiaProExtraLight}) format('opentype');;
}
@font-face {
  font-family: SofiaPro;
  font-style: italic;
  font-weight: 200;
  src:
    local('SofiaPro'),
    local('Sofia Pro'),
    url(${SofiaProExtraLightItalic}) format('opentype');;
}
@font-face {
  font-family: SofiaPro;
  font-style: normal;
  font-weight: 100;
  src:
    local('SofiaPro'),
    local('Sofia Pro'),
    url(${SofiaProUltraLight}) format('opentype');;
}
@font-face {
  font-family: SofiaPro;
  font-style: italic;
  font-weight: 100;
  src:
    local('SofiaPro'),
    local('Sofia Pro'),
    url(${SofiaProUltraLightItalic}) format('opentype');;
}

  body {
    font-family: SofiaPro;
		margin: 0;
		background-color: ${colors.white};
    input {
      font-family: inherit;
    }
        /* for scrollbar not moving content left on some pages*/
        padding-left: calc(100vw - 100%);
  }
`
export default GlobalStyle
