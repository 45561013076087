export default {
  black: '#212529',
  white: '#ffffff',
  redError: '#ff6b6b',
  lightRed: '#fff5f5',
  redErrorHover: '#F03E3E',
  violet: '#845EF7',
  violetHover: '#7048e8',
  orange: '#FCC419',
  orangeHover: '#fab005',
  lightOrange: '#fff9db',
  green: '#20C997',
  greenHover: '#0ca678',
  red: '#20C997',
  veryLightGrey: '#f8f9fa',
  lightGrey: '#F1F3F5',
  greyDividerBorder: '#dee2e6',
  greyTeritary: '#868e96',
  darkGrey: '#495057',
  veryDarkGrey: '#212529',
}
