import { appEventTypes } from './AppEventTarget'

class TokenChangeEvent extends CustomEvent {
  constructor(userToken) {
    super(appEventTypes.TOKEN_CHANGE, {
      detail: {
        userToken,
      },
    })
  }
}

export default TokenChangeEvent
