import { rootPaths } from './routesPaths'

export default [
  {
    path: rootPaths.LOGIN,
    exact: true,
    name: 'login-page',
    type: 'public',
    loader: () => import('../Login/Login'),
  },
  {
    path: rootPaths.BRIEF,
    exact: true,
    name: 'brief-page',
    type: 'token',
    loader: () => import('../BriefOnline/Brief'),
  },
  {
    path: rootPaths.HOME,
    exact: true,
    name: 'homepage',
    type: 'authed',
    loader: () => import('../Homepage/Homepage'),
  },
]
