import colors from '../../styles/colors'
import styled from 'styled-components'

export const BaseButton = styled.button`
  &:focus {
    outline: none;
  }
  border-radius: 8px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
`

export const BasePrimaryButton = styled(BaseButton)`
  color: ${colors.white};
  border: none;

  background-color: ${(props) => props.theme.main};

  cursor: pointer;
  :hover {
    background-color: ${(props) => props.theme.hover};
    color: ${colors.white};
    outline: none;
  }
`

export const PrimaryBigButton = styled(BasePrimaryButton)`
  width: 498px;
  height: 56px;

  :disabled {
    background-color: ${colors.greyDividerBorder};
    color: ${colors.greyTeritary};
    outline: none;
    cursor: not-allowed;
  }
`

export const PrimaryMediumButton = styled(BasePrimaryButton)`
  width: 187px;
  height: 48px;
`

export const PrimarySmallButton = styled(BasePrimaryButton)`
  width: 150px;
  height: 40px;
`

export const BaseSecondaryButton = styled(BaseButton)`
  width: ${(props) => props.width && props.width} !important;
  color: ${(props) => props.theme.main};

  border: solid 1px ${colors.greyDividerBorder};
  background-color: ${colors.veryLightGrey};

  cursor: pointer;
  :hover {
    background-color: ${colors.lightGrey};
    outline: none;
  }
`
export const SecondaryMediumButton = styled(BaseSecondaryButton)`
  width: 207px;
  height: 48px;
`

export const SecondarySmallButton = styled(BaseSecondaryButton)`
  width: 172px;
  height: 40px;
`
export const BackButton = styled(SecondaryMediumButton)`
  width: 100px;
  margin-right: 24px;
`
