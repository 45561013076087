import styled from 'styled-components'
import colors from '../../../shared/styles/colors'
import { ReactComponent as Logo } from '../../../shared/static/icons/gorrion_logo_basic.svg'

export const ProjectName = styled.div`
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  letter-spacing: normal;
  text-align: left;
  color: ${colors.veryDarkGrey};
`
export const StyledLogo = styled(Logo)`
  margin-right: 12px;
`

export const Container = styled.div`
  padding: 12px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`
